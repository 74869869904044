.map-container {
    height: 100vh;
}

#about {
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    position: absolute;
    background-color: white;

}

code {
    font-size: .7rem;
}

#about_content>img {
    max-width: 100%;

}

#about_content {
    max-width: 70rem;
    overflow: scroll;
    padding: 2rem;
    background-color: white;
}

.gui {
    position: fixed;
    z-index: 10;
    margin: 20px;
    background: rgba(39, 39, 39, 0.93);
    border: 1px solid rgb(33, 33, 33);
    color: white;
    border-radius: 10px;
    top: 0px;
    padding: 20px;
    width: 600px;
}


.gui button{
    background: none;
    color: white;
    font-size: 20px;
    line-height: 0px;
    height: 30px;
    width: 30px;
    border: none;
    text-align: center;
    border-radius: 50%;
    position:absolute;
    right: 20px;
    top: 15px;
    border: 1px grey dotted;
    padding-bottom: 5px;
    z-index: 3;
}

.gui button:hover {
    border: 1px grey solid;
    cursor: pointer;
}

/* 
.table_container {
    max-height: 300px;
    overflow-y: scroll;
} */


input[type=checkbox] {
    transform: scale(1.1);
}


.gui h3 {
    margin-top: 10px;
    line-height: 0px;
    font-size: 1.3em;
    margin-bottom: 10px;
    font-family:  sans-serif
}

h3 span {
    font-weight: 400;
    font-size: .7em;
    line-height: 0px;
    margin-left:  5px;
}

.gui table {
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: 30px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gui .hidden {
    display: none;
}

.gui table td {
    padding: 5px 5px 5px 5px;
    border: 1px dotted grey;
}

.gui a {
    text-decoration: none;
    color: grey;
}

.gui a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 800px) {
    .gui {
        bottom: 0px;
        top: auto;
        margin: 0px;
        width: calc(100% - 40px);
        border-radius: 0px;
        font-size: .9em;
    }
}